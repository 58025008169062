<template>
  <div class="mt-md-15" style="margin-bottom: 200px">
    <v-container v-if="company && company.name">
      <v-card elevation="2" color="#0049a0" dark class="card">
        <template>
          <v-card-title> Equipos del cliente: {{ company.name }} </v-card-title>
          <v-card-subtitle> {{ company.customId }} </v-card-subtitle>
        </template>
      </v-card>
    </v-container>
    <Card
      v-if="!noEquipments"
      v-for="equipment in equipments"
      :key="equipment.id"
      color="#1E1E1E"
      dark
    >
      <template>
        <v-card-title>
          Equipo: {{ equipment.name }} <v-spacer></v-spacer>
          <v-btn
            @click.stop="search(equipment.id)"
            v-if="equipment.id != equipmentId"
          >
            Ver conformes
          </v-btn>
        </v-card-title>
        <v-card-subtitle> {{ equipment.id }} </v-card-subtitle>
        <v-card-text v-if="equipment.id == equipmentId && loading">
          <v-progress-linear
            indeterminate
            rounded
            :active="loading"
          ></v-progress-linear>
          Buscando conformes...
        </v-card-text>
        <v-card-text v-if="!noServices && equipment.id == equipmentId">
          <v-list>
            <v-list-item v-for="service in services" :key="service.id">
              <v-list-item-avatar>
                <v-icon class="blue" dark>mdi-clipboard-text</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  Conforme N° {{ service.number }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  Fecha - {{ service.date }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    color="grey lighten-1"
                    @click="downloadPdf(service.pdf)"
                    >mdi-download</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <!-- <iframe
            :src="service.pdf"
            width="100%"
            height="350px"
            class="mb-2"
            v-for="service in services"
            :key="service.id"
          /> -->
        </v-card-text>
        <v-card-text
          v-if="noServices && equipment.id == equipmentId && !loading"
        >
          No se encontraron conformes
        </v-card-text>
      </template>
    </Card>
    <Card v-if="noEquipments" color="#1E1E1E">
      <v-card-title v-if="!loading && customId">
        No se encontraron resultados para el cliente con id: "{{ customId }}"
      </v-card-title>
      <v-row class="ma-0">
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
          v-if="loading"
          class="mx-auto my-10"
        ></v-progress-circular>
      </v-row>
    </Card>
  </div>
</template>

<script>
// import pdf from "vue-pdf";
import Card from "@/components/card.vue";
export default {
  name: "Client",
  data: () => ({}),
  computed: {
    company() {
      return this.$store.state.selectedCompany;
    },
    equipments() {
      return this.$store.state.searchedEquipments;
    },
    noEquipments() {
      return (
        this.$store.state.searchedEquipments == null ||
        this.$store.state.searchedEquipments.length <= 0
      );
    },
    services() {
      return this.$store.state.searchedServices;
    },
    noServices() {
      return (
        this.$store.state.searchedServices == null ||
        this.$store.state.searchedServices.length <= 0
      );
    },
    customId() {
      return this.$route.params.customId;
    },
    equipmentId() {
      return this.$route.params.equipmentId;
    },
    loading() {
      return this.$store.state.searching;
    },
  },
  components: {
    Card,
  },
  created() {
    if (this.customId)
      this.$store.dispatch("searchEquipmentsByCustomId", this.customId);
    if (this.equipmentId)
      this.$store.dispatch("searchServicesByEquipmentId", this.equipmentId);
  },
  methods: {
    search(equipmentId) {
      this.$router.push({
        name: "Client Services",
        params: { customId: this.customId, equipmentId: equipmentId },
      });
      if (equipmentId) {
        this.$store.dispatch("searchServicesByEquipmentId", equipmentId);
      }
    },
    downloadPdf(pdf) {
      window.open(pdf);
    },
  },
};
</script>

<style scoped>
iframe {
  border: 0;
}
.container {
  max-width: 915px !important;
}
</style>